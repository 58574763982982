const gameStateKey = 'gameState'

type StoredGameState = {
  guesses: string[]
  solution: string
  startTime: number
  solveTime: number
}

export const saveGameStateToLocalStorage = (gameState: StoredGameState) => {

  var wordKey = new Date().toJSON().slice(0,10).replace(/-/g,'').toString();

  var currentPath = window.location.pathname.replace('/', '');
  if (currentPath !== "") {
      wordKey = currentPath;
  }

  if (gameState.startTime === 0) {
    gameState.startTime = Date.now()
  }

  localStorage.setItem(wordKey + '-' + gameStateKey, JSON.stringify(gameState))
}

export const loadGameStateFromLocalStorage = () => {

  var wordKey = new Date().toJSON().slice(0,10).replace(/-/g,'').toString();

  var currentPath = window.location.pathname.replace('/', '');
  if (currentPath !== "") {
      wordKey = currentPath;
  }

  const state = localStorage.getItem(wordKey + '-' + gameStateKey)
  return state ? (JSON.parse(state) as StoredGameState) : null
}

const gameStatKey = 'gameStats'

export type GameStats = {
  winDistribution: number[]
  gamesFailed: number
  currentStreak: number
  bestStreak: number
  totalGames: number
  successRate: number
}

export const saveStatsToLocalStorage = (gameStats: GameStats) => {

  var currentPath = window.location.pathname.replace('/', '');
  if (currentPath !== "") {
      return;
  }

  localStorage.setItem(gameStatKey, JSON.stringify(gameStats))
}

export const loadStatsFromLocalStorage = () => {
  const stats = localStorage.getItem(gameStatKey)
  return stats ? (JSON.parse(stats) as GameStats) : null
}
