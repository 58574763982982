import { Cell } from './Cell'

type Props = {
  clueText: string
}

export const ClueRow = ({ clueText }: Props) => {
  return (
    <div className="flex justify-center mb-1">
      {clueText.split('').map((letter, i) => (
        <Cell key={i} value={letter} status="clue" />
      ))}
    </div>
  )
}
