import { ChartBarIcon } from '@heroicons/react/outline'
import { useState, useEffect } from 'react'
import { Alert } from './components/alerts/Alert'
import { Grid } from './components/grid/Grid'
import { ClueRow } from './components/grid/ClueRow'
import { Keyboard } from './components/keyboard/Keyboard'
import { AboutModal } from './components/modals/AboutModal'
import { InfoModal } from './components/modals/InfoModal'
import { StatsModal, formatTimeRun } from './components/modals/StatsModal'
import { WIN_MESSAGES } from './constants/strings'
import {
  getCurrentWord,
  isWinningWord,
  solution,
  showDate,
  getNextDate,
  getPreviousDate,
  getTodayDate,
  getIsToday,
  getClueWord,
  isWordAnAnagram,
} from './lib/words'
import { addStatsForCompletedGame, loadStats } from './lib/stats'
import {
  loadGameStateFromLocalStorage,
  saveGameStateToLocalStorage,
} from './lib/localStorage'

const ALERT_TIME_MS = 2000
const currentWord = getCurrentWord()
const isToday = getIsToday()
const currentDate = showDate(false)
const nextDate = getNextDate(currentDate)
const previousDate = getPreviousDate(currentDate)
const todayDate = getTodayDate()
const clueWord = getClueWord()

// Make sure we have a saved game state, needed for the timer
const checkLoaded = loadGameStateFromLocalStorage()
if (!checkLoaded) {
  saveGameStateToLocalStorage({
    guesses: [],
    solution,
    startTime: 0,
    solveTime: 0,
  })
}

function ShowPrevious() {
  if (parseInt(currentDate) > 20220101) {
    return (
      <a
        type="button"
        className="mx-auto mt-8 flex text-left px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 select-none"
        href={'/' + previousDate}
      >
        &#171; Previous Day
      </a>
    )
  }
  return null
}

function ShowToday() {
  if (!isToday) {
    return (
      <a
        type="button"
        className="mx-auto mt-8 flex text-right px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 select-none"
        href={'/' + todayDate}
      >
        Today :)
      </a>
    )
  }
  return null
}

function ShowNext() {
  if (!isToday) {
    return (
      <a
        type="button"
        className="mx-auto mt-8 flex text-right px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 select-none"
        href={'/' + nextDate}
      >
        Next Day &#187;
      </a>
    )
  }
  return null
}

function clearAllIntervals() {
  // Get a reference to the last interval + 1
  const interval_id = window.setInterval(function () {},
  Number.MAX_SAFE_INTEGER)

  // Clear any timeout/interval up to that id
  for (let i = 1; i < interval_id; i++) {
    window.clearInterval(i)
  }
}

function App() {
  const [currentGuess, setCurrentGuess] = useState('')
  const [isGameWon, setIsGameWon] = useState(false)
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const [isAboutModalOpen, setIsAboutModalOpen] = useState(false)
  const [isNotEnoughLetters, setIsNotEnoughLetters] = useState(false)
  const [isStatsModalOpen, setIsStatsModalOpen] = useState(false)
  const [isWordNotFoundAlertOpen, setIsWordNotFoundAlertOpen] = useState(false)
  const [isGameLost, setIsGameLost] = useState(false)
  const [startTime, setStartTime] = useState(0)
  const [solveTime, setSolveTime] = useState(0)
  const [successAlert, setSuccessAlert] = useState('')
  const [guesses, setGuesses] = useState<string[]>(() => {
    const loaded = loadGameStateFromLocalStorage()
    if (loaded?.solution !== solution) {
      return []
    }
    const gameWasWon = loaded.guesses.includes(solution)
    if (gameWasWon) {
      setIsGameWon(true)
    }
    if (loaded.guesses.length === 6 && !gameWasWon) {
      setIsGameLost(true)
    }
    setStartTime(loaded.startTime)
    setSolveTime(loaded.solveTime)
    return loaded.guesses
  })
  const [timeRun, setTimeRun] = useState('')

  const [stats, setStats] = useState(() => loadStats())

  useEffect(() => {
    var secondsRun = (Date.now() - startTime) / 1000
    setTimeRun(formatTimeRun(Math.floor(secondsRun)))
    saveGameStateToLocalStorage({ guesses, solution, startTime, solveTime })
  }, [guesses, startTime, solveTime])

  useEffect(() => {
    // Stop any existing timer
    clearAllIntervals()
    // Start new timer
    window.setInterval(
      (startTime: number) => {
        var secondsRun = (Date.now() - startTime) / 1000
        setTimeRun(formatTimeRun(Math.floor(secondsRun)))
      },
      1000,
      startTime
    )
  }, [startTime])

  // Stop running timer when finished
  useEffect(() => {
    if (isGameWon || isGameLost) {
      clearAllIntervals()
    }
    if ((isGameWon || isGameLost) && solveTime > 0) {
      var secondsRun = (solveTime - startTime) / 1000
      setTimeRun(formatTimeRun(Math.floor(secondsRun)))
    }
  }, [isGameWon, isGameLost, startTime, solveTime])

  useEffect(() => {
    if (isGameWon) {
      setSuccessAlert(
        WIN_MESSAGES[Math.floor(Math.random() * WIN_MESSAGES.length)]
      )
      setTimeout(() => {
        setSuccessAlert('')
        setIsStatsModalOpen(true)
      }, ALERT_TIME_MS)
    }
    if (isGameLost) {
      setTimeout(() => {
        setIsStatsModalOpen(true)
      }, ALERT_TIME_MS)
    }
  }, [isGameWon, isGameLost])

  const onChar = (value: string) => {
    if (
      currentGuess.length < currentWord.length &&
      guesses.length < 6 &&
      !isGameWon
    ) {
      setCurrentGuess(`${currentGuess}${value}`)
    }
  }

  const onDelete = () => {
    setCurrentGuess(currentGuess.slice(0, -1))
  }

  const onEnter = () => {
    if (isGameWon || isGameLost) {
      clearAllIntervals()
      return
    }
    if (!(currentGuess.length === currentWord.length)) {
      setIsNotEnoughLetters(true)
      return setTimeout(() => {
        setIsNotEnoughLetters(false)
      }, ALERT_TIME_MS)
    }

    if (!isWordAnAnagram(currentGuess)) {
      setIsWordNotFoundAlertOpen(true)
      return setTimeout(() => {
        setIsWordNotFoundAlertOpen(false)
      }, ALERT_TIME_MS)
    }

    const winningWord = isWinningWord(currentGuess)

    if (
      currentGuess.length === currentWord.length &&
      guesses.length < 6 &&
      !isGameWon
    ) {
      if (winningWord || guesses.length === 5) {
        clearAllIntervals()
        setSolveTime(Date.now())
      }
      setGuesses([...guesses, currentGuess])
      setCurrentGuess('')

      if (winningWord) {
        if (isToday) {
          setStats(addStatsForCompletedGame(stats, guesses.length))
        }
        return setIsGameWon(true)
      }

      if (guesses.length === 5) {
        if (isToday) {
          setStats(addStatsForCompletedGame(stats, guesses.length + 1))
        }
        setSolveTime(Date.now())
        setIsGameLost(true)
      }
    }
  }

  return (
    <div className="py-8 max-w-7xl mx-auto sm:px-6 lg:px-8">
      <div className="flex w-80 mx-auto items-center mb-2">
        <h1 className="text-xl grow font-bold">
          <a href="/">Raganam</a>
        </h1>
        <ChartBarIcon
          className="h-6 w-6 cursor-pointer"
          onClick={() => setIsStatsModalOpen(true)}
        />
      </div>
      <div className="flex w-80 mx-auto items-center mb-8">
        <h2 className="text-m grow">A daily anagram game</h2>
        <h2 className="text-m grow text-right">
          <a href={'/' + showDate(false)}> {showDate(true)} </a>
        </h2>
      </div>

      <ClueRow clueText={clueWord} />
      <br />

      <div
        className="text-xl grow text-center font-bold"
        id="timer"
        data-starttime={startTime}
      >
        {timeRun}
      </div>
      <br />

      <Grid guesses={guesses} currentGuess={currentGuess} />
      <Keyboard
        onChar={onChar}
        onDelete={onDelete}
        onEnter={onEnter}
        guesses={guesses}
      />
      <InfoModal
        isOpen={isInfoModalOpen}
        handleClose={() => setIsInfoModalOpen(false)}
      />
      <StatsModal
        isOpen={isStatsModalOpen}
        handleClose={() => setIsStatsModalOpen(false)}
        guesses={guesses}
        gameStats={stats}
        isGameLost={isGameLost}
        isGameWon={isGameWon}
        startTime={startTime}
        solveTime={solveTime}
        handleShare={() => {
          setSuccessAlert('Game copied to clipboard')
          return setTimeout(() => setSuccessAlert(''), ALERT_TIME_MS)
        }}
      />
      <AboutModal
        isOpen={isAboutModalOpen}
        handleClose={() => setIsAboutModalOpen(false)}
      />

      <div className="py-2 max-w-7xl mx-auto sm:px-6 lg:px-8">
        <div className="flex w-80 mx-auto items-center mb-2">
          <ShowPrevious />
          <ShowToday />
          <ShowNext />
        </div>
      </div>

      <div className="py-2 max-w-7xl mx-auto sm:px-6 lg:px-8">
        <div className="flex w-80 mx-auto items-center mb-2">
          <button
            type="button"
            className="mx-auto mt-8 flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 select-none"
            onClick={() => setIsAboutModalOpen(true)}
          >
            About this game
          </button>

          <button
            type="button"
            className="mx-auto mt-8 flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 select-none"
            onClick={() => setIsInfoModalOpen(true)}
          >
            How To Play
          </button>

          <a
            type="button"
            className="mx-auto mt-8 flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 select-none"
            href="https://www.powerlanguage.co.uk/wordle/"
          >
            Original Wordle
          </a>
        </div>
      </div>

      <Alert message="Not enough letters" isOpen={isNotEnoughLetters} />
      <Alert
        message="That's not an anagram :)"
        isOpen={isWordNotFoundAlertOpen}
      />
      <Alert message={`The word was ${solution}`} isOpen={isGameLost} />
      <Alert
        message={successAlert}
        isOpen={successAlert !== ''}
        variant="success"
      />
    </div>
  )
}

export default App
